import axios from 'axios';
import * as FileSaver from 'file-saver';
import Interceptor from './Interceptor';


class HttpService {
    constructor(options) {
        const { baseURL } = options;
        this.axiosInstance = axios.create({ baseURL });
        this.interceptor = new Interceptor(this.axiosInstance);
    }

    // async get(url, config) {
    //     const { data } = await this.axiosInstance.get(url, config);
    //     return data;
    // }

    // async post(url, payload, config) {
    //     const { data } = await this.axiosInstance.post(url, payload, config);
    //     return data;
    // }

    // async put(url, payload, config) {
    //     const { data } = await this.axiosInstance.put(url, payload, config);
    //     return data;
    // }

    // async patch(url, payload, config) {
    //     const { data } = await this.axiosInstance.patch(url, payload, config);
    //     return data;
    // }

    // async delete(url, payload, config) {
    //     const { data } = await this.axiosInstance.delete(url, { ...config, data: payload });
    //     return data;
    // } 

    async get(url, config) {
        try {
          const response = await this.axiosInstance.get(url, config);
          return this.handleResponse(response);
        } catch (error) {
          return this.handleError(error);
        }
      }


    async post(url, payload, config) {
        try {
          const response = await this.axiosInstance.post(url, payload, config);
          return this.handleResponse(response); // Başlık ve status kontrolü burada yapılacak
        } catch (error) {
          return this.handleError(error);
        }
      }

    async put(url, payload, config) {
        try {
          const response = await this.axiosInstance.put(url, payload, config);
          return this.handleResponse(response);
        } catch (error) {
          return this.handleError(error);
        }
      }
    
    async patch(url, payload, config) {
        try {
          const response = await this.axiosInstance.patch(url, payload, config);
          return this.handleResponse(response);
        } catch (error) {
          return this.handleError(error);
        }
      }
    
    async delete(url, payload, config) {
        try {
          const response = await this.axiosInstance.delete(url, { ...config, data: payload });
          return this.handleResponse(response);
        } catch (error) {
          return this.handleError(error);
        }
      }
    
    
    async downloadFile(url, fileName = '', config = {}) {
      const response = await this.axiosInstance.get(url, {responseType: 'blob', ...config});
      const file = new Blob([response.data]);
      FileSaver.saveAs(file, `${fileName}`);
      return response;
    }

    // Yanıtı ele al
  handleResponse(response) {
    // HTTP status kontrolü yapıyoruz
    if (response.status === 200) {
      // 200 OK ise yanıt verisini döndürüyoruz
      return {
        success: true,
        data: response.data,
        status: response.status,
        headers: response.headers,
      };
    } else {
      // Başka bir durum kodu varsa, hata olarak döndürüyoruz
      return {
        success: false,
        message: 'Request failed with status: ' + response.status,
        status: response.status,
        headers: response.headers,
      };
    }
  }

  // Hata durumunda işlemi yap
  handleError(error) {
    if (error.response) {
      // Sunucu yanıtı aldı, ancak hata ile geldi (örneğin 400 veya 500)
      return {
        success: false,
        message: error.response.data.message || 'An error occurred.',
        status: error.response.status,
        headers: error.response.headers,
      };
    } else if (error.request) {
      // İstek yapıldı ama sunucudan yanıt gelmedi
      return {
        success: false,
        message: 'No response from server.',
      };
    } else {
      // Diğer hata türleri (örneğin, yanlış URL vs.)
      return {
        success: false,
        message: error.message,
      };
    }
  }
}
    


export default HttpService;
