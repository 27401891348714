import React from 'react';
import { Menu } from 'antd';
import {  Link } from "react-router-dom";
import { useLocation } from 'react-router';
import { HomeOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

// const { SubMenu } = Menu;

function Submenu() {
    const location = useLocation();
    const { pathname } = location;

    return (
        <>
        {
            pathname === '/' ? (
                <Menu mode="horizontal" defaultSelectedKeys={['0']} className='submenu-style' inlineCollapsed={false}>
                    <Menu.Item key="2">
                        <Link to='/trend-konular'>
                            Trend Konular
                        </Link> 
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Link to=''>
                            Yeni Eklenen Konular
                        </Link> 
                    </Menu.Item>
                    <Menu.Item key="4">
                        <Link to=''>
                            Yeni Kullanıcı Mesajları
                        </Link> 
                    </Menu.Item> 
            </Menu>
            ): 
            pathname === '/blog' ? 'blog meu' : ''
        }
            
        </>
    );
}

export default Submenu;
