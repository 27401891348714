import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { useLocation } from "react-router-dom";
import { getSubcategorieDetailsApi } from '../../services/contents';

const CategorieDetails = () => {
  const location = useLocation();
  const url = location.pathname.split('/');
  const categoryId = url[1];
  const [subcategorieDetails, setSubcategorieDetails] = useState<any>({});
  const [subcategorie, setSubcategorie] = useState<any>([]);
  const [timeAgoMap, setTimeAgoMap] = useState<Record<string, string>>({});

  const getSubcategorieDetails = async (categoryId: any) => {
    try {
      const response: any = await getSubcategorieDetailsApi(categoryId);
      if (response) {
        setSubcategorieDetails(response.data.category);
        setSubcategorie(response.data.category.subcategories);
      }
    } catch (e: any) {
      // Handle error
    }
  };

  const formatTimeDifference = (nowDate: any) => {
    const now = new Date();
    const targetDate = new Date(nowDate);

   // Türkiye saat diliminde tarih ve saat hesaplamaları
   const optionsDate: Intl.DateTimeFormatOptions = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' };
   const optionsTime: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };

   // Geçerli bir tarih formatı oluşturuluyor
   const targetDateLocal = new Date(targetDate.toLocaleString('en-US', { timeZone: 'Europe/Istanbul' }));
   const nowLocal = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Istanbul' }));


   // Tarih ve saat farkı hesaplama
   const diffMilliseconds = nowLocal.getTime() - targetDateLocal.getTime();
   const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));
   const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
   const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));

   const targetDay = targetDateLocal.toLocaleDateString('tr-TR', optionsDate);
   const targetTime = targetDateLocal.toLocaleTimeString('tr-TR', optionsTime);


   if (diffMinutes < 60) {
     return `${diffMinutes} dakika önce`;
   } else if (targetDateLocal.getDate() === nowLocal.getDate() ) {
     return `Bugün ${targetTime}`;
   } else if (targetDateLocal.getDate() + 1  === nowLocal.getDate() ) {
     return `Dün ${targetTime}`;
   } else if (diffDays < 31) {
     return `${diffDays} gün önce`;
   } else {
     return targetDay;
   }
  };

  useEffect(() => {
    getSubcategorieDetails(categoryId);
  }, [categoryId]);

  useEffect(() => {
    const updatedTimeAgoMap = subcategorie.reduce((acc: Record<string, string>, item: any) => {
      acc[item.lastMessageDate] = formatTimeDifference(item.lastMessageDate);
      return acc;
    }, {});
    setTimeAgoMap(updatedTimeAgoMap);
  }, [subcategorie]);

  return (
    <>
      <div className='blog-card'>
        <div className='cardx'>
          <article className='article'>
            <div className='right-txtt'>
              <div className='box-icon'>
                <div className='icon-icon'>
                  <img alt='' src={`../home/${subcategorieDetails.iconPath}`} width='20px' />
                </div>
                <div className='card-bloc-ttle handle' style={{ display: 'flex' }}>
                  <a className='card-title'>{subcategorieDetails.name}</a>
                  <pre>{subcategorieDetails.description}</pre>
                </div>
              </div>
              <div className='right-card'>
                {subcategorie.map((i: any) => (
                  <div className='box-card' key={i.id}>
                    <div className='box-card-div'>
                      <article className='box-card-article'>
                        <div className='box-card-link'>
                          <div>
                            <a href={`/${subcategorieDetails.seoLink}/${i.seoLink}`}>{i.name}</a>
                          </div>
                          <div className='box-card-staticyclic'>
                            <span>
                              <pre>Açılan Konu Sayısı</pre>
                              {i.topicsCount}
                            </span>
                            <span>
                              <pre>Gelen Mesaj Sayısı</pre>
                              {i.messagesCount}
                            </span>
                          </div>
                        </div>
                        <div className='box-card-info'>
                          <div><Avatar size={40} icon={'N'} /></div>
                          <div className='information-div'>
                            <span style={{ color: '#000' }} className="box-title">
                              <a href={i.lastMessageTopicLink}>{i.lastMessageTopic}</a>
                            </span>
                            <span style={{ color: '#000' }} className='box-time'>{timeAgoMap[i.lastMessageDate]}</span>
                            <span style={{ color: '#000' }} className='box-user'>{i.lastMessageAuthor}</span>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
}

export default CategorieDetails;
