import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from 'moment';
import BlogBreadcrumb from "../../components/BlogBreadcrumb";
import { Pagination, Spin } from "antd";
import {getTopicListApi} from "../../services/contents"


const TopicsPage = () => {
  const location = useLocation();
  const url = location.pathname.split("/");
  const categoryUrl = url[2];
  const categoryUrlTop = url[1];
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [totalPage, setTotalPage] = useState<any>(10);
  const [topicsList, setTopicsList] = useState<any>([]);
  const [topicsInfo, setTopicsInfo] = useState<any>({});
  const [loading, setLoading] = useState(false)

  const getTopicList = async () => {
    try{
      setLoading(true);
      const response:any = await getTopicListApi(categoryUrl,currentPage,pageSize);
      if(response){
        setTopicsList(response.data.topics);
        setTopicsInfo(response.data);
        setTotalPage(response.data.totalPage);
        setLoading(false);
      }
    }catch(e:any){
      console.log(e);
      setLoading(false);
    }
  }
  const  breadcrumbItems = [
    { name: 'Anasayfa', url: '/' },
    { name: `${topicsInfo.categoryName}`, url: `/${categoryUrlTop !== undefined ? categoryUrlTop : '' }` },
  ];

useEffect(() => {
  getTopicList();
},[])

  return (
    <>
     <BlogBreadcrumb  breadcrumbItems={breadcrumbItems}/>
     <div className="topics-page ">
       <div className="topic-page-title" style={{marginBottom:'20px'}}>
        <h3 style={{marginBottom:'5px'}}>{topicsInfo.subcategoryName}</h3>
        <p>
        {topicsInfo.subcategoryDescription}
        </p>
      </div>
      <div style={{ textAlign: "left", marginTop: "20px" }}>
        <Pagination
          total={totalPage}
          // onChange={handlePageChange}
          showSizeChanger={true}
          pageSizeOptions={['10', '20', '50', '100']}
          style={{marginBottom:'20px'}} 
        />
      </div>
      <Spin spinning={loading}>
      <div className="topic-list">
        {topicsList.map((topic:any) => (
          <div key={topic.id} className="box-card box-card-div box-card-article" >
            <div className="topic-info">
              <div className="topic-left">
                <img src="https://via.placeholder.com/40" alt="avatar" className="avatar" />
                <div className="topic-details">
                  <h3><a href={`/topics/${topic.topicSeoLink}`}>{topic.topicTitle}</a></h3>
                  <p>{moment(topic.topicCreatedDate).format('DD-MM-YYYY')}</p>
                  <p>{topic.topicCreatorUsername}</p>
                </div>
              </div>
              <div className="topic-center">
                <p>{`Yorumlar: ${topic.totalMessageCount}`}</p>
                <p>{`Görüntülenme: ${topic.totalViewCount}`}</p>
              </div>
              <div className="topic-right">
                <div>
                  <p>{moment(topic.lastMessageDate).format('DD-MM-YYYY')}</p>
                  <p>{topic.lastMessageCreatorUsername}</p>
                </div>
                <div className="avatar-small">
                  <img src="https://via.placeholder.com/30" alt="last user avatar" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      </Spin> 
    </div>
    </>
   
  );
};

export default TopicsPage;