import React from "react";

const IntroductoryText = () => {

  return (
    <>
      <div className="introductory-text" style={{marginBottom:'15px'}}>
        <h2 className="align-center">Kadınların Gücünü Birleştiren Platforma Hoş Geldiniz!</h2>
        <h4>Merhaba!</h4>
        <p>
        Kadınların sesini duyurabileceği, fikirlerini paylaşabileceği ve destek alabileceği sıcak ve samimi bir topluluğa katılmaya ne dersiniz? Sitemizde kadınların çeşitli konularda özgürce konuşabildiği, birbirlerine ilham verdiği ve güçlendiği bir ortam sunuyoruz. 
        </p>
        <p>
        Yorum Yapın, fikirlerinizi paylaşarak topluluğumuza katkıda bulunun.Konu Açın, kendi ilgi alanlarınızda tartışmalar başlatın ve diğer üyelerle etkileşime geçin. :)
        <a href=""> Ücretsiz Üye Olmak için Tıklayın.</a>
        </p>
    
      </div>

    </>
  );
};

export default IntroductoryText;
