import React,{ useState, useEffect} from "react";
import { Row, Col, Button} from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import IntroductoryText from "../Home/IntroductoryText";
import CategoryBox from "../../components/CategoryBox";
import { LocalStorageService } from "../../helpers/LocalStorage";
import { getCategoriesDetailsApi} from '../../services/contents';

const HomePage = () => {
const navigate = useNavigate();
const ilkullanici = false;
const [categoriesDetails, setCategoriesDetails] = useState<any>([])
const users = LocalStorageService.getAuthToken();

const getCategoriesDetails = async () => {
  try{
    const response:any = await getCategoriesDetailsApi();
    if(response){
      setCategoriesDetails(response?.data.categories);
    }
  }catch(e:any) {
    console.log('e',e);
  }
}

const handleClick = () => {
  navigate("/yeni-konu")
};

useEffect(() => {
  getCategoriesDetails();
},[])

  return (
    <>
    { ilkullanici ? '' : <IntroductoryText/> }
    <Row>
      <Col span={24}>
      {
        users ? 
        <div className="home-btn">
        <Button type="primary" icon={<FileAddOutlined />} onClick={handleClick} size="middle">
          Yeni Konu Aç
        </Button>
        </div>: ''
      }
       
      </Col>
    </Row>
    <CategoryBox categoriesDetails={categoriesDetails}/>
    </>
  );
};

export default HomePage;
