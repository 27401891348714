import React,{useEffect, useState} from 'react';
import { List, Avatar, Typography, Badge, Button,Divider,Pagination  } from 'antd';
import moment from 'moment';
import { ClockCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import {getNotificationsApi} from '../../services/profile';

const { Title, Text } = Typography;

const NotificationsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [notifications, setNotifications] = useState<any>([]);

  const getNotifications = async () => {
    try{
      const response:any = await getNotificationsApi(currentPage,pageSize);
      if(response){
        setNotifications(response.data.notifications)
        console.log('re',response)
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const notificationsDetail = () => {
    console.log('Bildirim detayına gidercek')
  }

  const handlePageChange = (page:any,size:any) => {
    setCurrentPage(page);
  };


  useEffect(() => {
    getNotifications();
  },[pageSize, currentPage])
  
  return (
    <div title="Bildirimler" style={{ width:'100%', margin: '0 auto' }}>
      <Button type="default" style={{float:'right', marginBottom:'10px'}} >
          <DeleteOutlined/> Tüm Bildirimleri Sil
      </Button>
      <Divider/>
      {/* <Title level={4} className="profile-msg-title" >Bildirimler</Title> */}
      <List
        itemLayout="horizontal"
        className='notifications-list'
        dataSource={notifications}
        style={{minHeight:'400px'}}
        renderItem={(item:any) => (
          <List.Item
            actions={[
              <Text type="secondary">
                <ClockCircleOutlined /> {moment(item.notificationDate).format('DD-MM-YYYY')}
              </Text>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar />}
              title={
                <Title level={5} style={{ marginBottom: 0, cursor:'pointer' }} onClick={notificationsDetail}>
                  <Badge
                  //  dot={!item.isRead} // Okunmamış bildirimi vurgulamak için
                  dot={true}
                    style={{
                      marginRight: 10,
                    }}
                  />
                  {item.notificationContent}
                </Title>
              }
              // description={<Text>{item.content}</Text>}
            />
          </List.Item>
        )}
      />
      <div style={{ textAlign: "left", marginTop: "20px" }}>
        <Pagination
          className='ant-pagination-bottom'
          current={currentPage}
          pageSize={pageSize}
          total={10}
          onChange={handlePageChange}
          showSizeChanger={true}
          pageSizeOptions={['10', '20', '50', '100']} 
        />
      </div>
    </div>
  );
};

export default NotificationsPage;