import React, { useEffect,useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Editor({ setHtmlContent, htmlContent }:any) {
    const quillRef = useRef<any>(null);

    const modules = {
        toolbar: [
            [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video'],
            ['clean']                                         
        ],
    };

    const formats = [
        'header', 'font',
        'list', 'bullet',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'align',
        'color', 'background',
        'link', 'image', 'video'
    ];

useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (!quill) return;
    const currentSelection = quill.getSelection()?.index || 0;
    const blockquoteElement = document.createElement("p");
    const editorContainer = quill.root;
    editorContainer.insertBefore(blockquoteElement, editorContainer.children[currentSelection]);
      quill.focus();
},[])

    return (
        <>
            <ReactQuill  ref={quillRef} theme="snow" value={htmlContent} onChange={setHtmlContent} modules={modules} formats={formats} />
        </>
        
    );
}

export default Editor;