import {notification} from 'antd';
import {api} from '../services/apis';
import {LocalStorageService} from '../helpers/LocalStorage';

const getTopCategoriesApi = async (): Promise<Object> => {
  return await api.get(`categories`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const getSubCategoriesApi = async (categoryId:any): Promise<Object> => {
  return await api.get(`categories/${categoryId}/subcategories`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const setTopics = async (data: any): Promise<Object> => {
  return (
    await api.post(`topics`, data,  { headers: {
      'X-Visitor-Id': LocalStorageService.getVisitorId(),
      'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
      'X-Visitor-IP': LocalStorageService.getBrowserIp(),
      'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
      'X-Visitor-City': LocalStorageService.getBrowserCity()
     }} )
  );
};

const getCategoriesDetailsApi = async (): Promise<Object> => {
  return await api.get(`categories/details`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const getSubcategorieDetailsApi = async (categoryId:any): Promise<Object> => {
  return await api.get(`categories/${categoryId}/subcategories/details`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const getTopicMessageApi = async (categoryId:any,currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`topics/${categoryId}/messages?size=${pageSize}&page=${currentPage}`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const sendTopicMessageApi = async (topicId:any,content:any): Promise<Object> => {
  return await api.post(`topics/${topicId}/reply`, {content},
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const getTopicListApi = async (categoryUrl:any,currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`subcategories/${categoryUrl}/topics?size=${pageSize}&page=${currentPage}`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const setTopicLikesApi = async (id:any): Promise<Object> => {
  return await api.post(`topics/${id}/like`, null,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const setReplyLikesApi = async (id:any): Promise<Object> => {
  return await api.post(`replies/${id}/like`, null,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

export {
  getTopCategoriesApi,
  getSubCategoriesApi,
  setTopics,
  getCategoriesDetailsApi,
  getSubcategorieDetailsApi,
  getTopicMessageApi,
  sendTopicMessageApi,
  getTopicListApi,
  setTopicLikesApi,
  setReplyLikesApi
}