import {api} from '../services/apis';
import {LocalStorageService} from '../helpers/LocalStorage';

const getMessageApi = async (currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`messages?size=${pageSize}&page=${currentPage}`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const getMessageUserNameApi = async (userName:any, currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`messages/${userName}?size=${pageSize}&page=${currentPage}`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const sendMessageApi = async (userName:any, content:any): Promise<Object> => {
  return await api.post(`messages/${userName}/send`, {content},
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const deleteMessageApi = async (userName:any): Promise<Object> => {
  return await api.delete(`messages/${userName}`, '',
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const getNotificationsApi = async ( currentPage:any,pageSize:any ): Promise<Object> => {
  return await api.get(`notifications?size=${pageSize}&page=${currentPage}`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

export {
  getMessageApi,
  sendMessageApi,
  getMessageUserNameApi,
  getNotificationsApi,
  deleteMessageApi
}