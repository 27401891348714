import React, { useState } from "react";
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

const DraftEditor = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorContent, setEditorContent] = useState<any>("");

  // Stil değiştirme fonksiyonu (bold, italic, underline, vs.)
  const handleKeyCommand = (command:any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  // Stil butonlarına basıldığında
  const toggleInlineStyle = (style:any) => {
    const newState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(newState);
  };

  // Liste eklemek için
  const toggleBlockType = (blockType:any) => {
    const newState = RichUtils.toggleBlockType(editorState, blockType);
    setEditorState(newState);
  };

  // Editörün içeriğini güncelle
  const handleEditorChange = (state:any) => {
    setEditorState(state);
    const contentState = state.getCurrentContent();
    setEditorContent(convertToRaw(contentState)); // Ham içeriği JSON formatında kaydet
  };

  // JSON formatında kaydedilen içeriği tekrar editöre yüklemek
  const loadContent = () => {
    const contentState = convertFromRaw(editorContent);
    setEditorState(EditorState.createWithContent(contentState));
  };


  return (
    <div className="editor-container">
    <h2>Gelişmiş Draft.js Editörü</h2>
    
    {/* Stil butonları */}
    <div className="toolbar">
      <button onClick={() => toggleInlineStyle("BOLD")} className="toolbar-button">
        <i className="fa fa-bold"></i> {/* Bold */}
      </button>
      <button onClick={() => toggleInlineStyle("ITALIC")} className="toolbar-button">
        <i className="fa fa-italic"></i> {/* Italic */}
      </button>
      <button onClick={() => toggleInlineStyle("UNDERLINE")} className="toolbar-button">
        <i className="fa fa-underline"></i> {/* Underline */}
      </button>
      <button onClick={() => toggleBlockType("unordered-list-item")} className="toolbar-button">
        <i className="fa fa-list-ul"></i> {/* Bullet List */}
      </button>
      <button onClick={() => toggleBlockType("ordered-list-item")} className="toolbar-button">
        <i className="fa fa-list-ol"></i> {/* Numbered List */}
      </button>
      <button onClick={() => toggleBlockType("header-one")} className="toolbar-button">
        <i className="fa fa-header"></i> {/* H1 */}
      </button>
      <button onClick={() => toggleBlockType("header-two")} className="toolbar-button">
        <i className="fa fa-header"></i> {/* H2 */}
      </button>
      <button onClick={() => toggleInlineStyle("COLOR")} className="toolbar-button">
        <i className="fa fa-paint-brush"></i> {/* Color */}
      </button>
      <button onClick={() => toggleInlineStyle("LINK")} className="toolbar-button">
        <i className="fa fa-link"></i> {/* Link */}
      </button>
    </div>

    {/* Draft.js Editor bileşeni */}
    <div className="editor">
      <Editor
        editorState={editorState}
        onChange={handleEditorChange}
        handleKeyCommand={handleKeyCommand}
        placeholder="Metninizi buraya yazın..."
      />
    </div>

    <div>
      <h3>Editor İçeriği (JSON):</h3>
      <pre>{JSON.stringify(editorContent, null, 2)}</pre>
    </div>

    <button onClick={loadContent} className="load-button">
      Yükle
    </button>
  </div>
  );
};

export default DraftEditor;