import React, { useState } from "react";
import { Layout, Menu} from "antd";
import MyProfile from "../../components/MyProfile";
import ProfileMessages from "./ProfileMessages";
import MyTopics from "./MyTopics";
import TopicsFollow from "./TopicsFollow";
import NotificationsPage from "./NotificationsPage";


const Profile = () => {
  const { Sider, Content } = Layout;
  const [selectedMenu, setSelectedMenu] = useState<any>("1");


  const renderContent = () => {
    switch (selectedMenu) {
      case "1":
        return <ProfileMessages/>;
      case "2":
        return <NotificationsPage/>;
      case "3":
        return  <MyTopics/>;
      default:
        return <TopicsFollow/>;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", marginTop:'20px' }}>
      <MyProfile/>
      <Layout>
        <Sider width={200} style={{ background: "#fff" }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            style={{ height: "100%", borderRight: 0, paddingTop:'35px' }}
            onClick={(e) => setSelectedMenu(e.key)}
          >
            <Menu.Item key="1">Tüm Mesajlarım</Menu.Item>
            <Menu.Item key="2">Tüm Bildirimler</Menu.Item>
            <Menu.Item key="3">Açtığım Konular</Menu.Item>
            <Menu.Item key="4">Takip Ettiğim Konular</Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 0 0 24px" }}>
          <Content
            style={{
              background: "#fff",
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
            className="profile-main"
          >
            {renderContent()}
          </Content>
        </Layout>
      </Layout>
        
   
    </Layout>
  );
};

export default Profile;
