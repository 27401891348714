
import React, { useState,useRef,useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopicUserCard from "../../components/TopicUserCard";
import moment from 'moment';
import { Button, Badge, Pagination, Spin, Card, Space, Avatar, Typography } from "antd";
import { MessageOutlined, LikeOutlined,LikeFilled, UserOutlined, FieldTimeOutlined, SendOutlined } from "@ant-design/icons";
import {getTopicMessageApi, sendTopicMessageApi, setTopicLikesApi, setReplyLikesApi} from '../../services/contents';
import BlogBreadcrumb from "../../components/BlogBreadcrumb";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { LocalStorageService } from "../../helpers/LocalStorage";

const { Text } = Typography;

const TopicList = () => {
  const quillRef = useRef<any>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const url = location.pathname.split("/");
  const categoryId = url[2];

  const getCurrentPageFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get('sayfa');
    return page ? parseInt(page, 10) : 1; // Varsayılan olarak 1
  };

  const [currentPage, setCurrentPage] = useState(getCurrentPageFromUrl());
  const [pageSize, setPageSize] = useState(10);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const [loading,setLoading] = useState<any>(false);
  const [topicMessage,setTopicMessage] = useState<any>([]);
  const [topicData,setTopicData] = useState<any>([]);
  const [topicId,setTopicId] = useState<any>(''); 
  const [editorContent, setEditorContent] = useState<any>('');
  const [hovered, setHovered] = useState<number | null>(null);
  const [likeShow, setLikeShow] = useState<any[]>([]);

  const userName:any = LocalStorageService.getUserName();
  const users = LocalStorageService.getAuthToken();

  const  breadcrumbItems = [
    { name: 'Anasayfa', url: '/' },
    { name: `${topicData.categoryName}`, url: `/${topicData.categorySeoLink}` },
    { name: 'Gündem', url: '/topics/:urlName' },
  ];

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const getTopicMessage = async (currentPage:any,pageSize:any) => {
    try{
      setLoading(true)
      const response:any = await getTopicMessageApi(categoryId,currentPage,pageSize);
      if(response.data.topic){
        const transformedData = [
          response.data.topic, 
          ...response.data.replies,
        ];
        setLoading(false);
        setTopicData(response.data)
        setTopicMessage(transformedData);
        setTopicId(response.data.topicId)
        setTotalPageCount(response.data.totalDataCount); 
        setTotalData(response.data.totalPageCount); 
      }else{
        const transformedData = [
          ...response.data.replies,
        ];
        setTopicId(response.data.topicId);
        setLoading(false);
        setTopicData(response.data)
        setTopicMessage(transformedData);
        setTotalPageCount(response.data.totalDataCount); 
        setTotalData(response.data.totalPageCount); 
      }
      
    }catch(e:any){
      console.log(e);
      setLoading(false)
    }
  }

  const cleanReplyContent = (content:any) => {
    const withoutBlockquote = content.replace(/<blockquote[\s\S]*?<\/blockquote>/g, "");
    const onlyParagraphs = withoutBlockquote.match(/<p[\s\S]*?<\/p>/g);
    return onlyParagraphs ? onlyParagraphs.join("") : "";
  };

  const handleReply = (message: any) => {
    const quill = quillRef.current?.getEditor();
    if (!quill) return;
    const text = message.topicContent !== undefined ? message.topicContent : cleanReplyContent(message.replyContent) ;
    const name = message.topicContent !== undefined ? message.topicCreator.username  : message.replyCreator.username;

    const currentSelection = quill.getSelection()?.index || 0;
    const blockquoteElement = document.createElement("blockquote");
    blockquoteElement.setAttribute("data-quote", `@${name}`);
    
    const paragraphs = text.split(/<\/p>/g) 
    .filter((p:any)=> p.trim() !== '') 
    .map((p:any) => p.replace(/<[^>]*>/g, '')); 

    blockquoteElement.textContent = paragraphs.join('\n'); 

    const paragraphElement = document.createElement("p");
    paragraphElement.innerHTML = "<br>";

    const editorContainer = quill.root;
    editorContainer.insertBefore(blockquoteElement, editorContainer.children[currentSelection]);
    editorContainer.insertBefore(paragraphElement, blockquoteElement.nextSibling);

    const newPosition = currentSelection + blockquoteElement.textContent!.length ; 
    quill.setSelection(newPosition);

    quill.focus();
  };

  const handleEditorChange = (content:any) => {
    setEditorContent(content);
  };

  const handleLike = async(message:any) => {
    try{
      const response:any = message.topicContent ? 
      await setTopicLikesApi(message.topicId)
      :
      await setReplyLikesApi(message.replyId);

      if(response.status === 200){
         window.location.reload();
      }
    }catch(e:any){
      console.log(e)
    }
  }

  const handleMouseEnter = (index: number) => {
    setHovered(index);
  };

  const handleMouseLeave = () => {
    setHovered(null); 
  };

  const handleSendContent = async () => {
    const quill = quillRef.current?.getEditor();
    if (!quill) return;
    const editorContent1 = quill.root.innerHTML; 
    try{
      const response:any = await sendTopicMessageApi(topicId, editorContent1);
      if(response.status === 201){
        const quill = quillRef.current?.getEditor();
        if (quill) {
          quill.deleteText(0, quill.getLength()); // Tüm metni sil
        }
        setEditorContent('');
        
        const newTotalCount = totalPageCount + 1;
        const updatedTotalPageCount = Math.ceil(newTotalCount / pageSize);
        setTotalPageCount(updatedTotalPageCount);

        const lastPage = updatedTotalPageCount;
        setCurrentPage(lastPage);

        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('sayfa', lastPage.toString());
        navigate(`${location.pathname}?${newSearchParams.toString()}`);
        
        window.location.reload();

      }
    }catch(e:any){
      console.log(e)
    }
  };

  const handlePageChange = (page:any,size:any) => {
    setCurrentPage(page);
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('sayfa', page.toString());
    navigate(`${location.pathname}?${newSearchParams.toString()}`);
  };

   const checkLikeStatus = (message: any, index: number) => {
    if ( message.topicLikes) {
      return Object.keys(message.topicLikes).includes(userName);
    } else if (message.replyLikes) {
      return Object.keys(message.replyLikes).includes(userName);
    }
    return false;
  };

  useEffect(() => {
    const pageFromUrl = getCurrentPageFromUrl();
    setCurrentPage(pageFromUrl);
  }, [location.search]); 

  useEffect(() => {
    if (topicMessage) {
      const initialLikeStatus = topicMessage.map((message: any, index: number) => checkLikeStatus(message, index));
      setLikeShow(initialLikeStatus);
    }
  }, [topicMessage]);

  useEffect(() => {
    getTopicMessage(currentPage,pageSize);
    const quill = quillRef.current?.getEditor();
    if (!quill) return;
    const currentSelection = quill.getSelection()?.index || -1;
    const blockquoteElement = document.createElement("p");
    const editorContainer = quill.root;
    editorContainer.insertBefore(blockquoteElement, editorContainer.children[currentSelection]);
      quill.focus();
  },[currentPage,pageSize])
  

  return (
    <>
      <BlogBreadcrumb breadcrumbItems={breadcrumbItems} />
      <div className="topic-page-title">
        <h2>{topicData.topicTitle} </h2>
        <span>
          <UserOutlined /> {topicData.topicCreatorUsername} / <FieldTimeOutlined /> {moment(topicData.topicCreateDate).format('DD-MM-YYYY')}
        </span>
      </div>
      {topicMessage.map((message:any, index:any) => (
        <Spin spinning={loading}>
          <div className="blog-card" key={index}>
            <div className="cardx">
              <article className="article">
                <div className="right-txtt topic-list-div">
                  <div className="topic-user-profil">
                    <TopicUserCard 
                      userName={message.topicCreator ? message.topicCreator.username : message.replyCreator.username} 
                      status={message.topicCreator ? message.topicCreator.status : message.replyCreator.status} 
                      mesaj={message.topicCreator ? message.topicCreator.totalMessageCount : message.replyCreator.totalMessageCount} 
                      like={message.topicCreator ? message.topicCreator.totalLikeCount : message.replyCreator.totalLikeCount} 
                      date={message.topicCreator ? moment(message.topicCreator.registrationDate).format('DD-MM-YYYY')  : moment(message.replyCreator.registrationDate).format('DD-MM-YYYY')} 
                      badgeSaveUser={message.topicCreator ? message.topicCreator.badgeSaveUser : message.replyCreator.badgeSaveUser}  
                      badgeMomClub={message.topicCreator ? message.topicCreator.badgeMomClub : message.replyCreator.badgeMomClub }  
                      badgeDietClub={message.topicCreator ? message.topicCreator.badgeDietClub : message.replyCreator.badgeDietClub} 
                      userRole={message.topicCreator ? message.topicCreator.userRole : message.replyCreator.userRole}  
                    />
                  </div>
                  <div className="topic-msj">
                    <div>
                      <div className="topic-msj-date" style={{ position: "relative" }}>
                        {moment(message.topicCreatedDate).format('DD-MM-YYYY')}
                        {
                          message?.badges? 
                          (
                            <Badge.Ribbon
                            text="Yeni"
                            color="gold"
                            style={{ top: "-25px", right: "-17px" }}
                          />
                          ): ''
                        }
                        
                      </div>
                      {
                          message.topicContent && <div className="topic-msj-txt"  dangerouslySetInnerHTML={{ __html: message.topicContent }}></div>
                      }
                      {
                        
                          message.replyContent && <div className="topic-msj-txt"  dangerouslySetInnerHTML={{ __html: message.replyContent }}></div>
                      }
                    </div>
                    <div>
                      <div className="topic-msj-btn">
                        <div className="btn-right">
                          {/* <Button icon={<DislikeOutlined />}>Şikayet Et</Button> */}
                        </div>
                        <div className="btn-right" style={{display:'flex'}}>
                          <div style={{position:'relative'}}>
                          <Button  
                            icon={likeShow[index]  ?  <LikeFilled /> : <LikeOutlined />  }
                            onClick={() => handleLike(message)}
                            onMouseEnter={() => handleMouseEnter(index)}  
                            onMouseLeave={handleMouseLeave}
                            >
                             {likeShow[index] ?  'Beğendin' : 'Beğen' }
                            </Button>
                            <div className="like-info">
                                {hovered === index && (
                                  <div className="like-tooltip">
                                    <div>
                                      { message.topicLikes && message.topicLikes !== null ? 
                                        `${Object.keys(message.topicLikes).length} kişi beğendi.`
                                        : 
                                        message.replyLikes && message.replyLikes !== null ?
                                        `${Object.keys(message.replyLikes).length} kişi beğendi.` : '0 kişi beğendi.'
                                    }
                                    </div>
                                  </div>
                                )}
                              </div>

                          </div>
                         

                          <Button
                            icon={<MessageOutlined />}
                            onClick={() => handleReply(message)}
                          >
                            Yanıtla
                          </Button>
                        
 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </Spin>
      ))}

      <div style={{ textAlign: "left", marginTop: "20px" }}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalPageCount}
          onChange={handlePageChange}
          showSizeChanger={true}
          pageSizeOptions={['10', '20', '50', '100']} 
        />
      </div>

      {/* Yanıt Editörü */}
      {
        users ? 
        <div className="blog-card">
        <div className="cardx">
          <article className="article">
            <div className="right-txtt topic-list-div">
              <div className="topic-user-profil">
              <Card className="card-box">
                <Space direction="vertical" className="topic-user-avatar-space">
                  <Avatar
                    className={`topic-user-avatar online`}
                    icon={<UserOutlined />}
                    style={{ backgroundColor: "#C4C4A3" }}
                  />
                  <div className="card-p-mobil">
                    <div>
                      <Text strong style={{ fontSize: "16px" }}>
                        {userName}
                      </Text>
                      <Text>
                      <span style={{ color: "#52c41a" }}> Aktif</span>
                    </Text>
                    </div>
                  </div>
                </Space>
              </Card>
              </div>
              <div className="topic-msj">
                <div>
                  <div className="topic-msj-txt">
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    modules={modules}
                    value={editorContent} 
                    onChange={handleEditorChange} 
                    formats={[
                      "header",
                      "font",
                      "list",
                      "bullet",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "align",
                      "color",
                      "background",
                      "link",
                      "image",
                      "video",
                    ]}
                  />
                  </div>
                </div>
                <div>
                  <div className="topic-msj-btn" style={{justifyContent:'right', marginTop:'10px', background:'none'}}>
                    <div className="btn-right">
                      <Button
                      style={{backgroundColor:'#f6f3f3'}}
                        icon={<SendOutlined />}
                        onClick={handleSendContent}
                      >
                        Gönder
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div> : ''
      }
     

    </>
  );
};

export default TopicList;
