import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

function BlogBreadcrumb({breadcrumbItems}:any) {

    return (
        <>
            <Breadcrumb style={{ margin: '40px 0 15px 0' }}>
                {breadcrumbItems.map((item:any, index:any) => (
                    <Breadcrumb.Item key={index}>
                        <Link to={item.url}>{item.name}</Link>
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </>

    
    );
}

export default BlogBreadcrumb;
