/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = true;
      setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = false;
      setTimeout(callback, 100);
  },
};
  
const urls = {
  homePage: '/',
  login: '/giris',
  signup: '/kayit-ol',
  blog: '/blog',
  deneme:'/:link',


  // profile -setting
  profile:'/profilim',
  settings:'/ayarlar',
};


export  { fakeAuthProvider, urls };
  