import React, { useState } from "react";
import { Layout, Menu} from "antd";
import MyProfile from "../../components/MyProfile";
import AccountSettings from "./AccountSettings";
import SecuritySettings from "./SecuritySettings";
import PrivacySettings from "./PrivacySettings";
import NotificationSettingsPage from "./NotificationSettingsPage";


const Settings = () => {
  const { Sider, Content } = Layout;
  const [selectedMenu, setSelectedMenu] = useState<any>("1");


  const renderContent = () => {
    switch (selectedMenu) {
      case "1":
        return <AccountSettings/>;
      case "2":
        return <SecuritySettings/>;
      case "3":
        return <PrivacySettings/>;
      default:
        return <NotificationSettingsPage/>;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", marginTop:'20px' }}>
      <MyProfile/>
      <Layout>
        <Sider width={200} style={{ background: "#fff" }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            style={{ height: "100%", borderRight: 0, paddingTop:'35px' }}
            onClick={(e) => setSelectedMenu(e.key)}
          >
            <Menu.Item key="1">Hesap Ayarları</Menu.Item>
            <Menu.Item key="2">Şifre ve Güvenlik</Menu.Item>
            <Menu.Item key="3">Gizlilik</Menu.Item>
            <Menu.Item key="4">Bildirim Ayarları</Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 0 0 24px" }}>
          <Content
            style={{
              background: "#fff",
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
            className="profile-main"
          >
            {renderContent()}
          </Content>
        </Layout>
      </Layout>
        
   
    </Layout>
  );
};

export default Settings;
