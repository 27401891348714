import React, { useState } from 'react';
import { Card, Switch, Row, Col, Typography, Divider } from 'antd';

const { Title, Text } = Typography;

const notificationCategories = [
  {
    id: 1,
    name: 'Tüp Bebek',
    description: 'Tüp Bebek konusunda açılan tüm konular hakkında bildirim almak isterseniz bu seçeneği açın.',
  },
  {
    id: 2,
    name: 'Kadın ve Çocuk Sağlığı',
    description: 'Kadın ve Çocuk Sağlığı hakkında bildirim almak için bu seçeneği açın.',
  },
  {
    id: 3,
    name: 'Hesap Güvenliği',
    description: 'Hesabınızla ilgili güvenlik uyarıları almak için bu seçeneği açın.',
  },
  {
    id: 4,
    name: 'Pazarlama ve Kampanyalar',
    description: 'Kampanya ve özel tekliflerden haberdar olmak için bildirimleri açın.',
  },
];

const NotificationSettingsPage = () => {
  const [settings, setSettings] = useState(
    notificationCategories.reduce((acc:any, category:any) => {
      acc[category.id] = true; // Başlangıçta tüm bildirimler açık
      return acc;
    }, {})
  );

  // Switch durumu değiştiğinde ayarı güncelleme fonksiyonu
  const handleSwitchChange = (id:any, checked:any) => {
    setSettings((prevSettings:any) => ({
      ...prevSettings,
      [id]: checked,
    }));
  };

  return (
    <Card title="Bildirim Ayarları" style={{ maxWidth: 800, margin: '0 auto' }}>
      <Text type="secondary">İstediğiniz kategorilerden bildirim almayı seçebilirsiniz.</Text>
      <Divider />
      
      <Row gutter={[16, 16]}>
        {notificationCategories.map(category => (
          <Col span={24} key={category.id}>
            <Card>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={5} style={{ marginBottom: 0 }}>
                    {category.name}
                  </Title>
                  <Text type="secondary">{category.description}</Text>
                </Col>
                <Col>
                  <Switch
                    checked={settings[category.id]}
                    onChange={(checked) => handleSwitchChange(category.id, checked)}
                    checkedChildren="Açık"
                    unCheckedChildren="Kapalı"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default NotificationSettingsPage;